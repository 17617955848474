import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Was sind PowerApps?",
  "description": "No-Code App-Entwicklung auf der Microsoft-Plattform",
  "author": "Monica",
  "categories": ["powerplatform"],
  "date": "2021-07-30T00:00:00.000Z",
  "featured": false,
  "tags": ["powerapps"],
  "relatedArticles": ["how-to-install-gatsby-theme-helpsy"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Apps für Ihren Geschäftserfolg einfach erstellen - mit Ihren Daten!`}</p>
    <p>{`Sie benötigen statt mehrerer Monate nur noch wenige Stunden, um Ihre Apps zu entwickeln – diese ermöglichen eine einfache Einbindung von Daten, nutzen Excel-ähnliche Ausdrücke für die Logik und lassen sich sowohl im Web als auch auf iOS- und Android-Geräten ausführen.`}</p>
    <img src="/images/powerapps/powerapp2.png" alt="PowerApps" style={{
      "width": "100%"
    }} />
    <img src="/images/powerapps/powerapps-1.jpg" alt="PowerApps" style={{
      "width": "100%"
    }} />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      